.login-button {
    width: 120px;
}

.sign-up-button {
    width: 180px;
}


.login-background-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 500px;
    min-width: 320px;
    padding: 40px;
    gap: 20px;
    border-radius: 10px;
}


.login-button-box {
    padding: 0px 0px 10px 0px;
     gap: 20px
}

.tos-checkbox-container {
    display: flex;
    flex-direction: row;
    height: 42px;
    align-items: center;
    gap: 4px;
}