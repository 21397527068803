.first-splash-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 80%;
    max-width: 600px;
    min-width: 320px;
    padding: 40px;
    gap: 20px;
    border-radius: 10px;
}

.stepper-page-layout {
    background-color: white;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.stepper-form-section {
    display: flex;
    width: 100%;
    justify-content: left;
    align-items: center;
    padding-top: 40px;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
    @media screen and (max-width: 600px)  { 
        padding-left: 10px;
        padding-right: 10px;
    }


}

.page-step-container {
    position: relative;
    padding-bottom: 10px;
    gap:10px;
    display: flex;
    flex-direction: column;
    /* @media screen and (max-width: 600px)  { 
        padding-left: 20px;
        padding-right: 20px;
    } */

}

.business-name-text-input {
    margin-bottom: 50px;
}


.phone-number-text-field {
    margin-top: 25px;
}

.contact-email-text-field {
    margin-top: 15px;
    margin-bottom: 30px;
}


.daily-hours-input-container {
    margin-top: 40px;
}

.first-time-login-page-title {
    font-weight: 700 !important;
    font-size: 30px !important
}

.first-time-login-page-subheader {
   font-size: 18px !important;
   color: #8692A6 
}

.first-time-login-page-input-title {
    font-size: 16px !important;;
    margin-bottom: 14px !important;;
}