.flexCC {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexColumnCC {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.flexColumn {
  display: flex;
  flex-direction: column;
}

.logout-button {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
  margin-top: 10px;
  color: black !important;
  border-color: black !important
}

.logout-button-white {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 10px;
  margin-top: 10px;
  color: white !important;
  border-color: white !important
}

.imageUploadPlaceholder {
  border: var(--custom-border);
  border-radius: var(--custom-border-radius);
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageUpload {
  border-radius: var(--custom-border-radius);
  overflow: hidden;
  max-width: 100%;
}
.imageUpload.image {
  object-fit: cover;
}
.imageUpload.no-image {
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}


.color-wheel-value {
  border-left: 24px solid #000;
  padding-left: 10px;
  margin-top: 20px;
}


.business-hours-form-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  padding-bottom: 20px;
  flex-shrink: 0;
}


.open-hours-container {
  display: flex;
  align-items: flex-end; 
}


.service-image-remove-button-container {
  display: flex;
  @media screen and (max-width: 550px)  { 
    flex-direction: column;
    button {
      width: 100%
    }
  }
}

.dashboard-field-container {
  display: flex;
  flex-direction: row;
  height: 80vh;

  @media screen and (min-width: 1000px) {
    height: calc(100vh - 211px);
  }

  @media screen and (max-width: 1000px) {
    height: calc(100vh - 152px);
  }

  @media screen and (max-width: 600px) {
    height: 100%
  }
}



.lead-gen-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 10px 10px 10px 30px;

  @media screen and (max-width: 410px) {
    width: 100%;
    height: fit-content;
    box-sizing:border-box;
    transform: none;
    top: unset;
    left: unset;
    bottom: 0;
    padding: 30px 20px 30px 20px;
    text-align: center;
  }

  #modal-modal-title {
    @media screen and (max-width: 410px) {
      padding: 30px 0px;
    }
  }

  .lead-gen-modal-buttons {
    display: flex;
    gap: 10px;
    padding: 20px 0px 10px 0px;

    @media screen and (max-width: 410px) {
      align-items: center;
      justify-content: center;
      padding: 0px;    }
    }
}



.slick-prev:before,
.slick-next:before {
  color: #045872 !important;
}